import React, { useState } from 'react'
import { Carousel, Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { Swipeable } from 'react-swipeable'
import InstructionCard from './InstructionCard'
import Title from '../Title/Title'
import './InstructionCarousel.scss'

const DepositInstructionCarousel: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      step1: file(relativePath: { eq: "deposit-page/1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 799, layout: CONSTRAINED)
        }
      }
      step2: file(relativePath: { eq: "deposit-page/2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 799, layout: CONSTRAINED)
        }
      }
      step3: file(relativePath: { eq: "deposit-page/3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 799, layout: CONSTRAINED)
        }
      }
      step4: file(relativePath: { eq: "deposit-page/4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 799, layout: CONSTRAINED)
        }
      }
      step5: file(relativePath: { eq: "deposit-page/5.png" }) {
        childImageSharp {
          gatsbyImageData(width: 799, layout: CONSTRAINED)
        }
      }
      step6: file(relativePath: { eq: "deposit-page/6.png" }) {
        childImageSharp {
          gatsbyImageData(width: 799, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [visibleStep, setVisibleStep] = useState(0)
  const instructions = [
    {
      title: 'Step 1 > Click on Add Cash',
      image: data.step1.childImageSharp.gatsbyImageData,
      dotColor: '#37D6C2',
    },
    {
      title: 'Step 2 > Add Amount you want to deposit',
      image: data.step2.childImageSharp.gatsbyImageData,
      dotColor: '#FF3D3C',
    },
    {
      title: 'Step 3 > Apply the Offer Code',
      image: data.step3.childImageSharp.gatsbyImageData,
      dotColor: '#FFD608',
    },
    {
      title: 'Step 4 > Choose Payment Modes ',
      image: data.step4.childImageSharp.gatsbyImageData,
      dotColor: '#DDDDDD',
    },
    {
      title: 'Step 5 > Get Rewarded with Bonus Cashback',
      image: data.step5.childImageSharp.gatsbyImageData,
      dotColor: '#FF0D3C',
    },
  ]

  return (
    <div className="instructions">
      <Container>
        <Title>
          <span className="underline">How the offer works</span>
        </Title>
        <Swipeable
          preventDefaultTouchmoveEvent
          className="swipe-div d-block d-md-none"
        >
          <Carousel
            controls={false}
            indicators={false}
            interval={1000 * 1000}
            onSlide={i => {
              setVisibleStep(i)
            }}
          >
            {instructions.map(i => {
              return (
                <Carousel.Item key={i.title}>
                  <InstructionCard title={i.title} image={i.image} />
                </Carousel.Item>
              )
            })}
          </Carousel>
          <div className="dots">
            {instructions.map((t, i) => {
              return (
                <div
                  key={`${t.dotColor}deposit`}
                  className={`dot ${visibleStep === i ? 'active' : ''}`}
                  style={{ background: t.dotColor }}
                />
              )
            })}
          </div>
        </Swipeable>

        <div className="d-none d-md-block">
          <div className="desktop-grid">
            {instructions.map(i => {
              return (
                <InstructionCard
                  key={i.title}
                  title={i.title}
                  image={i.image}
                />
              )
            })}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default DepositInstructionCarousel
