import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import DepositInstructionCarousel from '../components/Download/DepositInstructionCarousel'
import DepositOffer from '../components/LandingPage/DepositOffer'
import Sms from '../components/Index/MainScreen/Desktop/Sms'
import './deposit-offers.scss'
import Title from '../components/Title/Title'
import PaymentPartners from '../components/Index/PaymentPartners/PaymentPartners'
import PopupDownload from '../components/PopupDownload/PopupDownload'
import StickyFooterMarquee from '../components/Download/StickyFooterMarquee'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'

const DepositOfferPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "deposit-page/amazing-deposit-offer.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1312, layout: FULL_WIDTH)
        }
      }
      bannerText: file(relativePath: { eq: "deposit-page/banner-text.png" }) {
        childImageSharp {
          gatsbyImageData(width: 604, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const handlePlay = () => {
    setIsPopupOpen(true)
  }

  const popupCode = isPopupOpen ? (
    <PopupDownload
      pokerLink={pokerOneLinksStaticPage}
      rummyLink={rummyOneLinksStaticPage}
      close={() => {
        setIsPopupOpen(false)
      }}
      gameName="Deposit Offer"
    />
  ) : null

  return (
    <>
      <Layout
        pokerOneLink={pokerOneLinksStaticPage}
        rummyOneLink={rummyOneLinksStaticPage}
      >
        <div className="deposit-top-cta">
          <Container>
            <Row>
              <Col className="cta-text">
                <StickyFooterMarquee
                  marqueeText={
                    <>
                      <span>
                      Play Poker & Get ₹30,000 Welcome Bonus. Use Code:
                        <strong> WELCOME30</strong>
                      </span>
                    </>
                  }
                />
              </Col>
              <Col className="cta-button">
                <button type="button" onClick={handlePlay}>
                  Signup Now
                </button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="deposit-full-banner">
          <GatsbyImage
            image={data.banner.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="deposit offers"
          />
          <div className="full-banner-content">
            <Container>
              <Row>
                <Col xs={6} lg={7} className="banner-image">
                  <GatsbyImage
                    image={data.bannerText.childImageSharp.gatsbyImageData}
                    loading="eager"
                    alt="deposit offers"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="deposit-page-content">
          <Container>
            <Title >
              Mega <span className="underline">Deposit</span> Offers
            </Title>
            <div className="deposit-page-table">
              <DepositOffer />
            </div>
          </Container>
          <DepositInstructionCarousel />
          <Container>
            <Sms hideTitle hideFeatures />
            <div className="terms-block">
              <Title >
                <span className="underline">Terms and Conditions</span>
              </Title>
              <ul>
                <li>Valid once per user</li>
                <li>Valid only for selected users</li>
                <li>Valid only on competitive games and not with friends</li>
                <li>Cashback will be given in Bonus Wallet</li>
                <li>
                  Don&apos;t miss task of any day to win all rewards. If you
                  miss a day in between you&apos;ll miss upcoming rewards
                </li>
              </ul>
            </div>
          </Container>
          <PaymentPartners
            title={
              <>
                <div>
                  Withdraw your winnings in less than{' '}
                  <span className="secs">60 seconds</span>
                </div>
              </>
            }
          />
        </div>
        {popupCode}
      </Layout>
      <SEO
        title="Mega Welcome Bonus - Amazing Bonus Cashback On Deposit"
        description="Are you looking to start playing rummy and poker games with a welcome bonus? Get up to Rs.10,000 welcome bonus on your deposit to Mega."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />
    </>
  )
}

export default DepositOfferPage
