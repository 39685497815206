import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const DepositOffer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(
        filter: {
          tags: { elemMatch: { slug: { eq: "hash-deposit_landing_page" } } }
        }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: data.allGhostPost.edges[0].node.html,
        }}
      />
    </>
  )
}

export default DepositOffer
